import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-services',
  templateUrl: './modern-services.component.html',
  styleUrls: ['./modern-services.component.scss']
})
export class ModernServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  services = [
    
    {
      img: "assets/images/app_landing2/service/2-3.png",
      title: "Match Predictor",
      description: "Make predictions on esports matches and earn points."
    },
    {
      img: "assets/images/app_landing2/service/2-2.png",
      title: "Create Private Pools",
      description: "Create private pools and compete against friends and colleagues."
    },
    {
      img: "assets/images/app_landing2/service/2-1.png",
      title: "Global & National Pools",
      description: "Compete with the wider community in Global and National Leaderboards."
    },
    // {
    //   img: "assets/images/app_landing2/service/2-4.png",
    //   title: "Team Collaboration",
    //   description: "Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant and actionable."
    // },
    // {
    //   img: "assets/images/app_landing2/service/2-5.png",
    //   title: "User Permissions",
    //   description: "Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant and actionable."
    // },
    // {
    //   img: "assets/images/app_landing2/service/2-6.png",
    //   title: "Unlimited Storage",
    //   description: "Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant and actionable."
    // }
  ]

}
