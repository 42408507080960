import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-modern-header',
  templateUrl: './modern-header.component.html',
  styleUrls: ['./modern-header.component.scss']
})
export class ModernHeaderComponent implements OnInit {
  appBaseUrl = environment.appBaseUrl;
  
  constructor() { }

  ngOnInit() {
  }

}
