
<!--about start-->
<section class="app2 about format">
<div class="animated-bg"><i></i><i></i><i></i></div>
    <div class="container">
        <div class="row">
            <div class="col-sm-5 counters set-height">
                <img src="assets/images/app_landing2/mobile.png" alt="" class="img-fluid mobile1">
            </div>
            <div class="col-sm-7 counters">
                <div class="abouts  center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="font-primary borders-before text-uppercase"><span>BECOME THE BEST FANTASY PLAYER IN THE WORLD</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header font-secondary">The ultimate fantasy esports experience is here</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="about-para">
                                Compete in leaderboards with other players from all around the world, earn points for picking the winning team, gain bonus points for guessing the correct score and earn streaks.</p>
                                <p class="about-para">
                                    The closer your predictions are the better your game difference will be which increases your chances of winning.
                                </p>
                        </div>
                        <!-- <ul class="icon-collection">
                            <li class="about-icon">
                                <a href="javascript:void(0)" class="center-content"><img
                                        src="assets/images/app_landing2/about-icons/1.png" alt="" class="img-fluid"></a>
                            </li>
                            <li class="about-icon">
                                <a href="javascript:void(0)" class="center-content"><img
                                        src="assets/images/app_landing2/about-icons/2.png" alt="" class="img-fluid"></a>
                            </li>
                            <li class="about-icon">
                                <a href="javascript:void(0)" class="center-content"><img
                                        src="assets/images/app_landing2/about-icons/3.png" alt="" class="img-fluid"></a>
                            </li>
                        </ul> -->
                        <a class="btn btn-default btn-gradient m-t-50" href="{{ appBaseUrl }}" target="_blank">PLAY NOW</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--about end-->