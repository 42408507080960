import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-modern',
  templateUrl: './modern.component.html',
  styleUrls: ['./modern.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModernComponent implements OnInit {
  loaded: boolean = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.loaded = true;
    console.info(`v${environment.version}`);
  }

}
