<!--header start-->
<section class="app2 header overflow-unset" id="home">
  <div class="app2-header bg">
      <div class="container">
          <div class="row">
              <div class="col-xl-7 col-md-6">
                  <div class="center-text">
                      <div>
                          <!-- <h6 class="header-top-line"># SuperPOG</h6> -->
                          <div class="header-text">
                              <h1>the Ultimate <span class="bold-text">esports fantasy</span> prediction app</h1>
                          </div>
                          <div class="header-sub-text">
                              <p class="text-white">The game is all about interaction, geared towards playing with your friends, colleagues, and the wider community.</p>
                          </div>
                          <div class="link-horizontal">
                              <ul>
                                  <li>
                                      <a class="btn btn-default btn-white" href="{{ appBaseUrl }}" target="_blank">Play Now</a>
                                  </li>
                                  <li>
                                      <a class="btn btn-default primary-btn transparent" href="https://play.google.com/store/apps/details?id=com.superpog.app" target="_blank">Google Play Store</a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-5 col-md-6">
                  <div class="img-mobile set-abs">
                      <img src="../assets/images/app_landing2/h2-mobile.png" alt="" class="header-image">
                  </div>
                  <!-- <div class="wave-orange"></div> -->
              </div>
          </div>
      </div>
      <div class="wave"></div>
  </div>
</section>
<!--header end-->