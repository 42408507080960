<!--function start-->
<section id="features" class="app2 format quality p-t-0">
    <div class="animated-bg"><i></i><i></i><i></i></div>
    <div class="container">
        <div class="row">
            <div class="col-sm-6 counters set-order-2">
                <div class="abouts center-text">
                    <div>
                        <div class="format-head-text">
                            <h3 class="about-font-header font-secondary">Sign up and join the fun!</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="about-para">Join the rest of our players and start making predictions right now. Sign up is quick and easy using Facebook or Google.</p>
                        </div>
                        <!-- <ul class="icon-collection">
                            <li class="about-icon">
                                <a href="#" class="center-content">
                                    <h4 class="quality">2 M</h4>
                                    <h6 class="users ">users</h6>
                                </a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content">
                                    <h4 class="quality">2.5 M</h4>
                                    <h6 class="users">downloads</h6>
                                </a>
                            </li>
                        </ul> -->
                        <a class="btn btn-default btn-gradient m-t-45" href="{{ appBaseUrl }}auth/signup" target="_blank">SIGN UP</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6  counters set-height overflow-hide"  [@fadeInRight]="fadeInRight">
                <img src="assets/images/app_landing2/l-2.png" alt="" class="img-fluid mobile2" 
                     data-aos-duration="1500">
            </div>
        </div>
    </div>
</section>
<!--function end-->
