import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-modern-about',
  templateUrl: './modern-about.component.html',
  styleUrls: ['./modern-about.component.scss']
})
export class ModernAboutComponent implements OnInit {
  appBaseUrl = environment.appBaseUrl;
  
  constructor() { }

  ngOnInit() {
  }

}
