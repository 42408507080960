import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { PagesRoutingModule } from './pages-routing.module';
import { SharedModule } from '../shared/shared.module'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PagesComponent } from './pages.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FaqComponent } from './faq/faq.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { AboutUsComponent } from './about-us/about-us.component';

@NgModule({
  declarations: [PagesComponent, FaqComponent, PageNotFoundComponent, MaintenanceComponent, AboutUsComponent],
  imports: [
    CommonModule,
    PagesRoutingModule,
    SharedModule,
    CarouselModule,
    NgbModule
  ]
})
export class PagesModule { }
