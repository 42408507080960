<!--screen-shot start-->
<section class="app2 screenshot p-t-0" id="screen-shot">
 <div class="animated-bg"><i></i><i></i><i></i></div>
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title2">
                    <img src="assets/images/logo/2.png" alt="" class="img-fluid title-img">
                    <h2 class="borders main-text"><span>screenshots</span></h2>
                    <div class="sub-title">
                    </div>
                </div>
            </div>
        </div>
        <div class="row set-relative">
            <div class="col-12">
                    <owl-carousel-o [options]="screenshotscarouselOptions" class="screenshot-slider" *ngIf="loaded">
                        <ng-container *ngFor="let screenshot of screenshots">
                            <ng-template carouselSlide class="item">
                                 <img [src]="screenshot.img" alt="" class="img-fluid">
                          </ng-template>
                        </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
</section>

<!-- <section class="app1 screenshot" id="screenshot">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 m-b-10">
                <div class="title title1">
                    <div class="main-title">
                        <h2 class="text-white">Screen Shot</h2>
                    </div>
                    <div class="sub-title">
                        <p class="text-white para">Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                            Lorem Ipsum has been</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row set-relative">
            <div class="col-12">
                <div class="swiper-container" [swiper]="config" [(index)]="index">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let slide of screenshots">
                            <img alt="" [src]="slide.img" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
            <div class="set-abs screen-container">
                <img src="assets/images/app_landing1/screenshot/mockup-2.png" alt="" class="img-fluid">
            </div>
        </div>
    </div>
  </section> -->
<!--screen-shot end-->
