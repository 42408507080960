import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-modern-screenshots',
  templateUrl: './modern-screenshots.component.html',
  styleUrls: ['./modern-screenshots.component.scss']
})
export class ModernScreenshotsComponent implements OnInit {
  public index: any;
  public loaded: boolean = false;

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.loaded = true;
    }, 100);
  }
  screenshots = [
    { img: "assets/images/app_landing2/screenshot/0.png" },
    { img: "assets/images/app_landing2/screenshot/1.png" },
    { img: "assets/images/app_landing2/screenshot/2.png" },
    { img: "assets/images/app_landing2/screenshot/3.png" },
    { img: "assets/images/app_landing2/screenshot/4.png" }

  ]

  screenshotscarouselOptions = {
    items: 3,
    margin: 55,
    autoHeight: true,
    nav: false,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
      0: {
        items: 1,
        margin: 10
      },
      360: {
        items: 2,
        margin: 10
      },
      480: {
        margin: 15
      },
      576: {
        margin: 15
      },
      1000: {
        items: 3
      }
    }
  }
}
