import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-team-member',
  templateUrl: './modern-team-member.component.html',
  styleUrls: ['./modern-team-member.component.scss']
})
export class ModernTeamMemberComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  users = [
    { 
      img:"assets/images/app_landing2/team/1.png",
      name:"Mike McCabe",
      designation:"Founder/CEO",
    },
    { 
      img:"assets/images/app_landing2/team/2.png",
      name:"Aaron Morse",
      designation:"Founder/Developer",
    },
    { 
      img:"assets/images/app_landing2/team/3.png",
      name:"Marc Upsher",
      designation:"Founder/Developer",
    }
  ]
}
