import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FaqComponent } from './faq/faq.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { AboutUsComponent } from './about-us/about-us.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '404',
        component: PageNotFoundComponent
      },
      {
        path: 'faq',
        component: FaqComponent,
        data: {
          title: "PAGES WITH FAQS",
          breadcrumb: "Faqs"
        }
      },
      {
        path: 'maintenance',
        component: MaintenanceComponent
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
        data: {
          title: "About Us",
          breadcrumb: "About Us"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
